<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#FDCE48"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#FDCE48" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #000">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #000">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-app id="inspire">
      <v-layout wrap justify-center class="login_page">
        <v-flex xs12 sm6 md4 lg3 px-2 align-self-center>
          <v-layout wrap justify-center>
            <v-flex xs112 xl12>
              <v-card tile flat>
                <v-layout wrap justify-center>
                  <v-flex xs12 px-8 pt-12 py-6>
                    <v-layout wrap justify-center>
                      <v-flex xs12 py-4>
                        <router-link to="/">
                          <span
                            style="
                              font-family: poppinsbold;
                              font-size: 20px;
                              cursor: pointer;
                              color: #545454;
                            "
                          >
                            Hive Keep
                          </span>
                        </router-link>
                      </v-flex>
                      <v-flex xs12 pt-4>
                        <span
                          style="
                            font-family: poppinsmedium;
                            font-size: 22px;
                            color: #090909;
                          "
                        >
                          Select Profile to Login
                        </span>
                      </v-flex>
                      <v-flex xs12 pt-8>
                        <v-layout wrap justify-center>
                          <v-flex
                            v-for="(item, i) in storage.availableroles"
                            :key="i"
                            pa-2
                          >
                            <v-card
                              @click="changeUser(item)"
                              :color="item == user ? '#FDCE48' : '#FFFFFF'"
                            >
                              <v-layout wrap justify-center pa-4>
                                <v-flex xs12>
                                  <span
                                    style="
                                      font-family: poppinsmedium;
                                      font-size: 16px;
                                      color: #090909;
                                    "
                                  >
                                    {{ item }}
                                  </span>
                                </v-flex>
                              </v-layout>
                            </v-card>
                          </v-flex>
                        </v-layout>
                      </v-flex>

                      <v-flex xs12 pt-8>
                        <v-btn
                          block
                          tile
                          color="#FDCE48"
                          dark
                          :ripple="false"
                          depressed
                          @click="validateInput"
                        >
                          <span
                            style="
                              font-family: poppinssemibold;
                              font-size: 12px;
                              text-transform: none;
                            "
                          >
                            Continue
                          </span>
                        </v-btn>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-app>
  </div>
</template>
<script>
import axios from "axios";
import store from "./../../store";
export default {
  props: ["storage"],
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      user: null,
    };
  },
  methods: {
    changeUser(item) {
      this.user = item;
    },
    validateInput() {
      this.login();
    },
    login() {
      if (this.user == this.storage.signingData.role) {
        store.commit("userType", this.storage.signingData.role);
        store.commit("userData", this.storage.signingData);
        store.commit("loginUser", this.storage.signingData.token);
      } else {
        this.appLoading = true;
        this.storage.auth["role"] = this.user;
        axios({
          method: "POST",
          url: "/user/login",
          data: this.storage.auth,
        })
          .then((response) => {
            this.appLoading = false;
            if (!response.data.supervisorrequest) {
              store.commit("userType", response.data.data.role);
              store.commit("userData", response.data.data);
              store.commit("loginUser", response.data.data.token);
            } else {
              this.$emit("stepper", {
                response: response.data,
                winStep: 2,
              });
            }
          })
          .catch((err) => {
            this.ServerError = true;
            console.log(err);
          });
      }
    },
  },
};
</script>